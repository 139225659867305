import nav from "./nav";

let routesData = [];
// 多维数据变一纬数组 ==》递归
nav.forEach(_forEach);


function _forEach(data) {
  if (data.children) {
    data.children.forEach(_forEach);
  } else {
    routesData.push(data);
  }
}

const routes = [
  {
    path: "/",
    component: () => import("../views/layout/AppLayout"),
    children: [
      {
        path: "",
        redirect: routesData[0].path,
      },
      {
        path: "/login",
        component: () => import("../views/LoginView"),
      },
      {
        path: "/register",
        component: () => import("../views/RegisterView"),
      },
      ...routesData,
    ],
  },
  {
    path: "/:catchAll(.*)",
    roles: [],
    component: () => import("../views/404View"),
  },
];

export {
  routesData,
  routes
};

