import { defineStore } from 'pinia'
import {
  login as loginApi,
  anonymousLogin as anonymousLoginApi,
  modifyUserInfo as modifyUserInfoApi
} from "@/api/account";
import { computed, ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {

  const userinfo = ref(null);

  const readFromStorage = () => {
    userinfo.value = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_STORE_USERINFO_KEY) ||
      sessionStorage.getItem(process.env.VUE_APP_STORE_USERINFO_KEY));

    console.log(userinfo);
  }

  const username = computed(() => {
    if (!userinfo.value) readFromStorage();
    if (!userinfo.value) return null;
    return userinfo.value.username;
  });

  const name = computed(() => {
    if (!userinfo.value) readFromStorage();
    if (!userinfo.value) return null;
    return userinfo.value.name;
  });

  const mobile = computed(() => {
    if (!userinfo.value) readFromStorage();
    if (!userinfo.value) return null;
    return userinfo.value.mobile;
  });

  const mail = computed(() => {
    if (!userinfo.value) readFromStorage();
    if (!userinfo.value) return null;
    return userinfo.value.mail;
  });

  const role = computed(() => {
    if (!userinfo.value) readFromStorage();
    if (!userinfo.value) return null;
    return userinfo.value.role;
  });

  const token = computed(() => {
    if (!userinfo.value) readFromStorage();
    if (!userinfo.value) return null;
    return userinfo.value.token;
  });

  const anonymousLogin = () => {
    return new Promise((resolve, reject) => {
      anonymousLoginApi()
        .then((res) => {
          userinfo.value = {
            name: res.name,
            role: res.role,
            token: res.token,
          }
          localStorage.setItem(process.env.VUE_APP_STORE_USERINFO_KEY, JSON.stringify(userinfo.value));
          sessionStorage.setItem(process.env.VUE_APP_STORE_USERINFO_KEY, JSON.stringify(userinfo.value));

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const login = (username, password) => {
    return new Promise((resolve, reject) => {
      loginApi(username, password)
        .then((res) => {
          userinfo.value = {
            username: res.username,
            name: res.name,
            mobile: res.mobile,
            mail: res.mail,
            role: res.role,
            token: res.token,
          }

          localStorage.setItem(process.env.VUE_APP_STORE_USERINFO_KEY, JSON.stringify(userinfo.value));
          sessionStorage.setItem(process.env.VUE_APP_STORE_USERINFO_KEY, JSON.stringify(userinfo.value));

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const modifyUserInfo = (password, name, mobile, mail) => {
    return new Promise((resolve, reject) => {
      modifyUserInfoApi(password, name, mobile, mail)
        .then(() => {
          userinfo.value.name = name;
          userinfo.value.mobile = mobile;
          userinfo.value.mail = mail;

          localStorage.setItem(process.env.VUE_APP_STORE_USERINFO_KEY, JSON.stringify(userinfo.value));
          sessionStorage.setItem(process.env.VUE_APP_STORE_USERINFO_KEY, JSON.stringify(userinfo.value));

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const logout = () => {
    return new Promise((resolve /*, reject*/) => {
      userinfo.value = null;

      localStorage.clear();
      sessionStorage.clear();

      resolve();
    });
  };

  return {
    userinfo,
    username,
    name,
    mobile,
    mail,
    role,
    token,
    login,
    anonymousLogin,
    modifyUserInfo,
    logout
  }
});
