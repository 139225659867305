import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import { useAuthStore } from "@/store/auth";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // api 的 base_url
  timeout: 10000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    const { token } = useAuthStore();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    console.log({
      url: config.baseURL + config.url,
      params: config.params,
      data: config.data,
    }); // for debug
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    console.log(res); // for debug
    return res;
  },
  (error) => {
    console.log(error);

    if (error.response && error.response.status === 401) {
      ElMessageBox.confirm(
        "你已被登出，可以取消继续留在该页面，或者重新登录",
        "确定登出",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        useAuthStore().logout().then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    } else {

      let message = error.message;

      if (error.response && error.response.data)
        message = error.response.data

      if (error.response && error.response.data && error.response.data.message)
        message = error.response.data.message

      ElMessage({
        message,
        type: "error",
        duration: 5 * 1000,
      });
    }

    return Promise.reject(error);
  }
);

export default service;
