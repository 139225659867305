import { createRouter, createWebHistory } from "vue-router";
import { routesData, routes } from "./config";
import { useAuthStore } from "@/store/auth";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { token, role } = useAuthStore();

  console.log(token, role);

  console.log(routesData);

  const firstRoute = routesData.find(i => i.roles && i.roles.some(j => j === role));
  console.log(firstRoute);
  const toRoute = routesData.find(i => i.path === to.path && i.roles && i.roles.some(j => j === role));
  console.log(toRoute);
  let toPath = toRoute ? toRoute.path : firstRoute ? firstRoute.path : null;
  console.log(toPath);

  if (to.path === "/login" || to.path === "/register") {
    if (!token) {
      next();
      return;
    }
  } else {
    if (!token) {
      toPath = "/login";
    }
  }

  if (toPath === to.path) {
    next();
  } else {
    next(toPath);
  }
});

export default router;
