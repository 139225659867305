import request from "@/utils/request";
import md5 from "js-md5";

export function register(username, password, name, mobile, mail) {
  return request({
    url: "/Account/Register",
    method: "post",
    params: {
      username,
      password: md5(password),
      name,
      mobile,
      mail,
    },
  });
}

export function login(username, password) {
  return request({
    url: "/Account/Login",
    method: "post",
    params: {
      username,
      password: md5(password),
    },
  });
}

export function anonymousLogin() {
  return request({
    url: "/Account/AnonymousLogin",
    method: "post",
  });
}

export function modifyUserInfo(password, name, mobile, mail) {
  password = password ? md5(password) : null;
  return request({
    url: "/Account/ModifyUserInfo",
    method: "post",
    params: {
      password,
      name,
      mobile,
      mail,
    },
  });
}

export function getUserList(params) {
  return request({
    url: "/Account/GetUserList",
    method: "post",
    params,
  });
}

export function upsertUser(data) {
  const data1 = {
    ...data
  };
  data1.password = !data.password ? null : md5(data.password);

  return request({
    url: "/Account/UpsertUser",
    method: "post",
    data: data1,
  });
}

export function disableUser(id) {
  return request({
    url: "/Account/DisableUser",
    method: "post",
    params: {
      id,
    },
  });
}
