import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);

import { createPinia } from "pinia";
const pinia = createPinia();
app.use(pinia)

import router from "./router";
app.use(router)

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
app.use(ElementPlus, { locale })

import * as Icons from '@element-plus/icons-vue'

// 注册全局 element-icons 组件
Object.keys(Icons).forEach((key) => {
    app.component(key, Icons[key])
})

import ElxCascader from "@/components/ElxCascader.vue"
app.component("ElxCascader", ElxCascader)

app.mount("#app");
