<template>
  <el-cascader v-model="value" :options="options" :props="treeProps" :show-all-levels="false" :placeholder="placeholder"
    clearable />
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  modelValue: {
    type: Number,
    default: null
  },
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value && value.length > 0 ? value[value.length - 1] : null);
  }
})

const treeProps = {
  expandTrigger: 'hover',
}
</script>
