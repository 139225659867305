const nav = [
  {
    path: "/user",
    label: "用户管理",
    icon: "UserFilled",
    roles: ['admin', 'warehouse'],
    component: () => import("../views/UserMgr/ListView"),
  },
  {
    path: "/country",
    label: "国家/地区管理",
    icon: "MapLocation",
    roles: ['admin', 'warehouse'],
    component: () => import("../views/CountryMgr/ListView"),
  },
  {
    path: "/port",
    label: "港口管理",
    icon: "LocationInformation",
    roles: ['admin', 'warehouse'],
    component: () => import("../views/PortMgr/ListView"),
  },
  {
    path: "/model",
    label: "集装箱型号管理",
    icon: "Menu",
    roles: ['admin', 'warehouse'],
    component: () => import("../views/ContainerModelMgr/ListView"),
  }, {
    path: "/container",
    label: "集装箱管理",
    icon: "Box",
    roles: ['admin', 'warehouse'],
    component: () => import("../views/ContainerMgr/ListView"),
  }, {
    path: "/stock",
    label: "库存统计",
    icon: "Files",
    roles: ['admin', 'warehouse'],
    component: () => import("../views/StockView/ListView"),
  },
  {
    path: "/stock_in",
    label: "批量入库",
    icon: "Fold",
    roles: ['warehouse'],
    component: () => import("../views/StockView/StockInView"),
  },
  {
    path: "/stock_out",
    label: "批量出库",
    icon: "Expand",
    roles: ['warehouse'],
    component: () => import("../views/StockView/StockOutView"),
  },
  {
    path: "/sale",
    label: "销售管理",
    icon: "Money",
    roles: ['admin', 'seller'],
    component: () => import("../views/SaleMgr/ListView"),
  },
  {
    path: "/approve",
    label: "审批管理",
    icon: "DocumentChecked",
    roles: ['admin', 'approver'],
    component: () => import("../views/ApproveMgr/ListView"),
  },
  {
    path: "/client_setting",
    label: "客户端设置",
    icon: "Setting",
    roles: ['admin'],
    component: () => import("../views/ClientSetting/SettingView"),
  },
  {
    path: "/msg",
    label: "查看用户留言",
    icon: "ChatRound",
    roles: ['admin', 'approver'],
    component: () => import("../views/Client/MessageListView"),
  },
  {
    path: "/client",
    label: "客户端",
    icon: "Tickets",
    roles: ['member', 'client'],
    component: () => import("../views/Client/ListView"),
  }
];

export default nav;
